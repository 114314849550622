.header-container {
    background-color: #3a4572;
    z-index: 999;
    width: 100%;
    position: fixed;
}

.navbar-container {
    max-width: 1024px;
    margin: auto;
    height: 80px;
}

.btn-tog {
    padding-top: 15px;
    color: #ffffff;
}

.main-item {
    color: #979797 !important;
    font-size: 16px;
    margin-left: 0;
    width: 100%;
}

.list-header {
    list-style: none;
    padding-left: 15px;
    font-size: 16px;
}

.items {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
}

.dropdown-items {
    color: #ffffff;
    font-size: 12px;
    padding-top: 12px;
    margin-right: 20px;
}


.item:hover, .item:active, .item:visited:hover, .main-item:hover, .main-item:active, .main-item:visited:hover {
    color: #ee7810 !important;
    text-decoration: none !important;
}

.item, .item:visited, .main-item, .main-item:visited {
    color: #979797 !important;
    font-size: 49px;
}

.nav-link {
    margin-left: -20px;
}

.dropdown-item:active {
    background-color: #ffffff;
}

.dropdown-menu {
    min-width: 250px;
    padding: 24px 16px 10px 6px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0 25px 0 rgba(24, 37, 90, 0.1);
}

.nav-item {
    line-height: initial;
}

.navbar-nav div:hover > .dropdown-menu {
    display: block;
}