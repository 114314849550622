.cookies-container {
    background-color: #18255a;
    padding: 18px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0.8;
    z-index: 9999;

}

.cookies-text {
    font-weight: 300;
    padding: 12px;
}

.accept-cookies-btn {
    background-color: white;
    color: #18255a;
    max-width:80px;
    margin: 10px auto;
    padding:5px 0;
    border-radius: 8px;
    cursor:pointer;
}

.close-green {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
}

.cookies-link {
    text-decoration: underline;
    color: #ffffff;
}