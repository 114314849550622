.bread-container {
    padding-top: 60px;
    padding-bottom: 120px;
    margin: auto;
    text-align: center;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbs-container {
    display: inline-flex;
    flex-wrap: wrap;
}

.bread-title {
    font-size: 30px;
    color: #18255a;
    margin-bottom: 50px;
}

.bread-image {
    margin-left: 30px;
    margin-right: 28px;
}

.bread-text {
    font-size: 12px;
    color: #666666;
}

.last-bread {
    color: #00a298;
}
