.benefits {
    text-align: center;
    max-width: 288px;
    margin: auto;
    padding-top: 50px;
}

.benefits-son {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.benefits-daughter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
}

.benefits-container {
    padding-bottom: 120px;
    padding-top: 120px;
    margin-top: -52px;
}

.title-benefits-style {
    text-align: center;
    font-size: 30px;
    color: #18255a;
}

.benefits-subtitle {
    font-size: 24px;
    margin-top: 58px;
    color: #666666;
}

.benefits-description {
    margin-top: 24px;
    color: #979797;
    font-size: 20px;
    margin-bottom: 0px;
}

.benefits-text1 {
    font-size: 40px;
    font-family: Raleway;
    color: #18255a;
    margin-bottom: 0px;
}

.benefits-description1 {
    font-size: 30px;
    font-family: Lato;
    color: #979797;
}

.benefits-text2 {
    font-size: 100px;
    font-weight: bold;
    font-family: Lato;
    color: #18255a;
    margin-bottom: 0px;
    text-align: center;
    height: 120px;
}

.benefits-description2 {
    font-size: 32px;
    color: #979797;
    text-align: center;
}
