.carousel-control.left, .carousel-control.right {
    background-image: none;
    margin-top: 6%;
}

.comments {
    margin-left: 40px;
    margin-right: 40px;
}

.comments-image {
    margin: auto;
    margin-top: 30px;
}

.comments-link a:hover, .comments-link a:active, .comments-link a:visited:hover, .comments-link a, .comments-link a:visited, .comments-link a:visited {
    color: #ffffff;
    text-decoration: none;
}

.slider-container {
    background-color: #18255a;
    padding-top: 74px;
    padding-bottom: 74px;
}

.slider-style {
    max-width: 924px;
    width: 75%;
    margin: auto;
}

.partner-style {
    width: 270px;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-arrow  {
    opacity: 0.5;
}

.slick-arrow:hover  {
    color: #fff;
    text-decoration: none;
    outline: 0;
    filter: alpha(opacity=90);
    opacity: 0.9;
}