.image-tree{
    width: 95%;
    margin-right: 8px;
    margin-left: -10px;
    padding-bottom: 120px;
    text-align:"center";
}

.title-tree{
    text-align: left;
    font-size: 30px;
    margin-bottom: 50px;
    color: #00a298;

}

.image-cirlce{
    margin-right: 8px;
    margin-left: -10px;
    padding-bottom: 120px;
    width: 65%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
