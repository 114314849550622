.footer-container {
    background-color: #44474b;
    padding-top: 72px;
    padding-bottom: 62px;
}

.logo-footer-style {
    margin-top: 28px;
    width: 95%;

}

.footer-title {
    font-size: 12px;
    color: #00a298;
}

.footer-text {
    font-size: 16px;
    color: #ffffff;
}
.footer-texts {
    font-size: 16px;
    color: #ffffff;
}

.privacy-style {
    font-size: 16px;
    color: #666666;
}

.privacy-styles {
    font-size: 16px;
    color: #979797;
}

.privacy-style:hover, .privacy-style:active, .privacy-style:visited:hover, .privacy-style:hover, .privacy-style:visited:hover {
    color: #ee7810;
    text-decoration: none;
}

.privacy-style, .privacy-style:visited {
    color: #979797;
    text-decoration: none;
}
