.cell {
    border-radius: 8px;
    background-color: #f5f5f5;
    text-align: center;
    margin: 4px;
}

.cell-text {
    font-size: 12px;
    line-height: normal;
    color: #00a298;
}

.cell-number {
    font-size: 16px;
    line-height: normal;
    color: #979797;
}

.image-cell {
    border-radius: 8px;
    text-align: center;
    height: 132px;
    padding-top: 28px;
}

.title-customer {
    font-size: 14px;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: left;
    color: #ffffff;
}

.description-customer {
    position: absolute;
    bottom: 0;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    text-align: left;
    font-size: 14px;
    color: #ffffff;
}

.card {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 132px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.card .imgBx {
    position: absolute;
    transition: .5s;
    width: 70%;
}


.card .details {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: .5s;
}

.card .details {
    background-color: #444e79;
    top: 100%;
}

.card:hover .details {
    top: 0;
    left: 0;
}

.card .details p {
    color: #ffffff;
}