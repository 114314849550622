.feature-text {
    font-size: 20px;
    line-height: normal;
    text-align: left;
    color: #979797;
}

.icon {
    float: left;
    display: inline-block;
    margin-top: 7px;
}

.green-bold {
    font-weight: 600;
    color: #88c91f;
}

.blue-bold {
    font-weight: 600;
    color: #3aa5d2;
}
