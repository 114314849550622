.video-section-container {
    max-width: 784px;
    margin: auto;
}
.video-container {
    display: flex;
    justify-content: center;
}

.video-wrapper {
    position: relative;
    overflow: hidden;
    width: 90%;
    padding-top: 56.25%;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}