.title-h2-style {
    text-align: center;
    padding-top: 320px;
    padding-bottom: 50px;
    font-size: 30px;
    color: #18255a;
    font-family: 'Raleway', sans-serif;
}

.row-contact-style {
    padding-bottom: 60px;
}

.map-style {
    text-align: center;
    padding: 20px;
}

.map-pointer {
    cursor: pointer;
}

.city-name {
    font-size: 30px;
    margin-top: 25px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #18255a;
    font-family: 'Raleway', sans-serif;
}

.city-address {
    font-size: 20px;
    text-align: center;
    color: #979797;
}

.city-address > a {
    color: rgba(68, 78, 121, 1);
    text-decoration: none;
}

.city-address > a:hover, a:active, a:visited:hover, a:hover, a:visited:hover {
    color: #ee7810;
    text-decoration: none;
}