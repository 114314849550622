.features-text-buying {
    font-size: 20px;
    color: #979797
}

.list-buying-style {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-title-buying {
    color: #666666;
    font-size: 20px;
    padding-bottom: 24px;
}

.list-buying {
    color: #979797;
}

.image-card-buying {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.image-card-paper {
    padding: 42px;
    min-height: 401px;
}