html > body {
    font-family: 'Lato', sans-serif;

}

div > p {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
}

div > h2 {
    font-family: 'Raleway', sans-serif;
}

div > h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 48px;
}

.external {
    max-width: 1024px;
    margin: auto;
}

a:hover, a:active, a:visited:hover, a:hover, a:visited:hover {
    color: #ee7810;
    text-decoration: none;
}

.triple-latam-container {
    padding-bottom: 120px;
    flex-wrap: wrap;
}

.triple-latam-styles {
    display: flex;
    justify-content: center;
    font-size: 48px;
    padding-bottom: 40px;
    color: #18255a;
    font-family: "Raleway", sans-serif;
}

.tree {
    background: rgba(68, 78, 121, 1);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(68, 78, 121, 1)), color-stop(100%, rgba(24, 37, 90, 1)));
    background: -webkit-linear-gradient(left, rgba(68, 78, 121, 1) 0%, rgba(24, 37, 90, 1) 100%);
    background: linear-gradient(to right, rgba(68, 78, 121, 1) 0%, rgba(24, 37, 90, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#444e79', endColorstr='#18255a', GradientType=1);
    padding-top: 120px;
    padding-left: 10px;
}

a, a:visited {
    color: #ffffff;
    text-decoration: none;
}

.dropdown-toggle {
    color: #ffffff !important;
}

.bg-grey {
    background-color: #f5f5f5;
}

@-webkit-keyframes QUOGO {
    25% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    35% {
        right: 0;
        -webkit-transform: translateY(25%);
                transform: translateY(25%);
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        right: 100%;
        -webkit-transform: translateY(25%);
                transform: translateY(25%);
    }
}

@keyframes QUOGO {
    25% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    35% {
        right: 0;
        -webkit-transform: translateY(25%);
                transform: translateY(25%);
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        right: 100%;
        -webkit-transform: translateY(25%);
                transform: translateY(25%);
    }
}

.QUO {
    display: none;
    bottom: 0;
    position: fixed;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    right: 0;
}

.QUO-go {
    -webkit-animation: QUOGO 2500ms;
            animation: QUOGO 2500ms;
}

.lazy-load-image-background.blur {
    -webkit-filter: blur(15px);
            filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    -webkit-filter: blur(0);
            filter: blur(0);
    -webkit-transition: -webkit-filter .3s;
    transition: -webkit-filter .3s;
    transition: filter .3s;
    transition: filter .3s, -webkit-filter .3s;
}

.lazy-load-image-background.blur > img {
    opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    opacity: 1;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
}

.title-style {
    position: relative;
    color: #ffffff;
    text-align: center;
    min-height: 644px;
    display: flex;
    align-items: center;
}

.title-contact-style {
    color: #ffffff;
    background: url(/static/media/bgDefault.ab410851.svg) repeat-x center center;
}

.title-contact-title-style{
    font-size: 48px;
    color: #ffffff;
}

.title-h1-style {
    text-align: left;
}

.title-h1-contact-style {
    padding-top: 80px;
    text-align: left;
}

.first-description-style {
    padding-right: 50px;
    padding-top: 25px;
    text-align: left;
}

.label-style {
    width: 100%;
}

.form-text-style {
    text-align: left;
    font-size: 48px;
    color: #ee7810;
    font-family: "Raleway", sans-serif;
}

.btn-next {
    position: absolute;
    margin-top: 400px;
    width: 100%;
}

.form-container {
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    height: 680px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 25px 0 rgba(24, 37, 90, 0.1);
    z-index: 500;
}

.btn-send {
    width: 144px;
    height: 40px;
    border-radius: 20px;
    margin-top: 24px;
    background-color: #ee7810;
    color: #ffffff;
    border: none;
}

.contact-text {
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ee7810;
}

.who-style {
    text-align: left;
    font-size: 14px;
}

.who-style .form {
    font-weight: bold;
    color: #666666;
    font-size: 14px;
}

.who-style input[type="text"] {
    border: solid 1px #e4e4e4;
    margin-top: 24px;
    border-radius: 8px;
    height: 48px;
    max-width: 408px;
    width: 100%;
    padding-left: 30px;
}

.who-style input[type="email"] {
    border: solid 1px #e4e4e4;
    border-radius: 8px;
    margin-top: 24px;
    height: 48px;
    max-width: 408px;
    width: 100%;
    padding-left: 30px;
}

.who-style input[type="checkbox"] {
    margin-top: 24px;
    margin-right: 12px;
}

textarea {
    border: solid 1px #e4e4e4;
    border-radius: 8px;
    max-width: 408px;
    width: 100%;
    resize: none;
    margin-top: 24px;
    padding-left: 30px;
    padding-top: 25px;
}

.benefits {
    text-align: center;
    max-width: 288px;
    margin: auto;
    padding-top: 50px;
}

.benefits-son {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.benefits-daughter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
}

.benefits-container {
    padding-bottom: 120px;
    padding-top: 120px;
    margin-top: -52px;
}

.title-benefits-style {
    text-align: center;
    font-size: 30px;
    color: #18255a;
}

.benefits-subtitle {
    font-size: 24px;
    margin-top: 58px;
    color: #666666;
}

.benefits-description {
    margin-top: 24px;
    color: #979797;
    font-size: 20px;
    margin-bottom: 0px;
}

.benefits-text1 {
    font-size: 40px;
    font-family: Raleway;
    color: #18255a;
    margin-bottom: 0px;
}

.benefits-description1 {
    font-size: 30px;
    font-family: Lato;
    color: #979797;
}

.benefits-text2 {
    font-size: 100px;
    font-weight: bold;
    font-family: Lato;
    color: #18255a;
    margin-bottom: 0px;
    text-align: center;
    height: 120px;
}

.benefits-description2 {
    font-size: 32px;
    color: #979797;
    text-align: center;
}

.carousel-control.left, .carousel-control.right {
    background-image: none;
    margin-top: 6%;
}

.comments {
    margin-left: 40px;
    margin-right: 40px;
}

.comments-image {
    margin: auto;
    margin-top: 30px;
}

.comments-link a:hover, .comments-link a:active, .comments-link a:visited:hover, .comments-link a, .comments-link a:visited, .comments-link a:visited {
    color: #ffffff;
    text-decoration: none;
}

.slider-container {
    background-color: #18255a;
    padding-top: 74px;
    padding-bottom: 74px;
}

.slider-style {
    max-width: 924px;
    width: 75%;
    margin: auto;
}

.partner-style {
    width: 270px;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-arrow  {
    opacity: 0.5;
}

.slick-arrow:hover  {
    color: #fff;
    text-decoration: none;
    outline: 0;
    filter: alpha(opacity=90);
    opacity: 0.9;
}
.video-section-container {
    max-width: 784px;
    margin: auto;
}
.video-container {
    display: flex;
    justify-content: center;
}

.video-wrapper {
    position: relative;
    overflow: hidden;
    width: 90%;
    padding-top: 56.25%;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.feature-image {
    text-align: center;
}

.feature-text-col {
    color: #979797;
    padding-top: 12px;
    padding-bottom: 48px;
}

.feature-text {
    font-size: 20px;
    line-height: normal;
    text-align: left;
    color: #979797;
}

.icon {
    float: left;
    display: inline-block;
    margin-top: 7px;
}

.green-bold {
    font-weight: 600;
    color: #88c91f;
}

.blue-bold {
    font-weight: 600;
    color: #3aa5d2;
}

.image-tree{
    width: 95%;
    margin-right: 8px;
    margin-left: -10px;
    padding-bottom: 120px;
    text-align:"center";
}

.title-tree{
    text-align: left;
    font-size: 30px;
    margin-bottom: 50px;
    color: #00a298;

}

.image-cirlce{
    margin-right: 8px;
    margin-left: -10px;
    padding-bottom: 120px;
    width: 65%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.features-text-buying {
    font-size: 20px;
    color: #979797
}

.list-buying-style {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-title-buying {
    color: #666666;
    font-size: 20px;
    padding-bottom: 24px;
}

.list-buying {
    color: #979797;
}

.image-card-buying {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.image-card-paper {
    padding: 42px;
    min-height: 401px;
}
.bread-container {
    padding-top: 60px;
    padding-bottom: 120px;
    margin: auto;
    text-align: center;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbs-container {
    display: inline-flex;
    flex-wrap: wrap;
}

.bread-title {
    font-size: 30px;
    color: #18255a;
    margin-bottom: 50px;
}

.bread-image {
    margin-left: 30px;
    margin-right: 28px;
}

.bread-text {
    font-size: 12px;
    color: #666666;
}

.last-bread {
    color: #00a298;
}

.table-subtitle {
    margin: auto;
    text-align: center;
    margin-bottom: 48px;
    font-size: 30px;
    color: #00a298;
}

.image-table-style {
    display: flex;
    flex-wrap: wrap;
    padding-top: 60px;
    padding-bottom: 120px;
}

.triple-image-table-style {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
}

.table-section-style {
    display: flex;
    flex-wrap: wrap;
}

.container-table {
    padding-bottom: 120px;
    padding-top: 120px;
}

.cell {
    border-radius: 8px;
    background-color: #f5f5f5;
    text-align: center;
    margin: 4px;
}

.cell-text {
    font-size: 12px;
    line-height: normal;
    color: #00a298;
}

.cell-number {
    font-size: 16px;
    line-height: normal;
    color: #979797;
}

.image-cell {
    border-radius: 8px;
    text-align: center;
    height: 132px;
    padding-top: 28px;
}

.title-customer {
    font-size: 14px;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: left;
    color: #ffffff;
}

.description-customer {
    position: absolute;
    bottom: 0;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    text-align: left;
    font-size: 14px;
    color: #ffffff;
}

.card {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 132px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.card .imgBx {
    position: absolute;
    -webkit-transition: .5s;
    transition: .5s;
    width: 70%;
}


.card .details {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: .5s;
    transition: .5s;
}

.card .details {
    background-color: #444e79;
    top: 100%;
}

.card:hover .details {
    top: 0;
    left: 0;
}

.card .details p {
    color: #ffffff;
}
.gallery-style {
    padding-top: 60px;
    padding-bottom: 49px;
}

.gallery-title-style {
    padding-bottom: 120px
}

.gallery-row-style {
    max-width: 1150px;
    margin-top: -30px;
}

.title-h2-gallery {
    font-size: 30px;
    color: #18255a;
}

.rounded-container {
    text-align: center;
}
.rounded-displayer-text {
    color: #979797;
    margin-bottom: 54px;
    margin-top: 16px;
    font-size: 20px;
}

.special-bold {
    margin-bottom: 25px;
    font-size: 30px;
    color: #18255a;
}

.special-normal {
    margin-top: 10px;
    font-size: 20px;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: #979797;
}

.description {
    max-width: 650px;
    margin-top: 25px;
    font-size: 20px;
    font-stretch: normal;
    line-height: normal;
    color: #949493;
}

.title-h2-style {
    text-align: center;
    padding-top: 320px;
    padding-bottom: 50px;
    font-size: 30px;
    color: #18255a;
    font-family: 'Raleway', sans-serif;
}

.row-contact-style {
    padding-bottom: 60px;
}

.map-style {
    text-align: center;
    padding: 20px;
}

.map-pointer {
    cursor: pointer;
}

.city-name {
    font-size: 30px;
    margin-top: 25px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #18255a;
    font-family: 'Raleway', sans-serif;
}

.city-address {
    font-size: 20px;
    text-align: center;
    color: #979797;
}

.city-address > a {
    color: rgba(68, 78, 121, 1);
    text-decoration: none;
}

.city-address > a:hover, a:active, a:visited:hover, a:hover, a:visited:hover {
    color: #ee7810;
    text-decoration: none;
}
.footer-container {
    background-color: #44474b;
    padding-top: 72px;
    padding-bottom: 62px;
}

.logo-footer-style {
    margin-top: 28px;
    width: 95%;

}

.footer-title {
    font-size: 12px;
    color: #00a298;
}

.footer-text {
    font-size: 16px;
    color: #ffffff;
}
.footer-texts {
    font-size: 16px;
    color: #ffffff;
}

.privacy-style {
    font-size: 16px;
    color: #666666;
}

.privacy-styles {
    font-size: 16px;
    color: #979797;
}

.privacy-style:hover, .privacy-style:active, .privacy-style:visited:hover, .privacy-style:hover, .privacy-style:visited:hover {
    color: #ee7810;
    text-decoration: none;
}

.privacy-style, .privacy-style:visited {
    color: #979797;
    text-decoration: none;
}

.header-container {
    background-color: #3a4572;
    z-index: 999;
    width: 100%;
    position: fixed;
}

.navbar-container {
    max-width: 1024px;
    margin: auto;
    height: 80px;
}

.btn-tog {
    padding-top: 15px;
    color: #ffffff;
}

.main-item {
    color: #979797 !important;
    font-size: 16px;
    margin-left: 0;
    width: 100%;
}

.list-header {
    list-style: none;
    padding-left: 15px;
    font-size: 16px;
}

.items {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
}

.dropdown-items {
    color: #ffffff;
    font-size: 12px;
    padding-top: 12px;
    margin-right: 20px;
}


.item:hover, .item:active, .item:visited:hover, .main-item:hover, .main-item:active, .main-item:visited:hover {
    color: #ee7810 !important;
    text-decoration: none !important;
}

.item, .item:visited, .main-item, .main-item:visited {
    color: #979797 !important;
    font-size: 49px;
}

.nav-link {
    margin-left: -20px;
}

.dropdown-item:active {
    background-color: #ffffff;
}

.dropdown-menu {
    min-width: 250px;
    padding: 24px 16px 10px 6px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0 25px 0 rgba(24, 37, 90, 0.1);
}

.nav-item {
    line-height: normal;
    line-height: initial;
}

.navbar-nav div:hover > .dropdown-menu {
    display: block;
}
.cookies-container {
    background-color: #18255a;
    padding: 18px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0.8;
    z-index: 9999;

}

.cookies-text {
    font-weight: 300;
    padding: 12px;
}

.accept-cookies-btn {
    background-color: white;
    color: #18255a;
    max-width:80px;
    margin: 10px auto;
    padding:5px 0;
    border-radius: 8px;
    cursor:pointer;
}

.close-green {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
}

.cookies-link {
    text-decoration: underline;
    color: #ffffff;
}
