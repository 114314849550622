.gallery-style {
    padding-top: 60px;
    padding-bottom: 49px;
}

.gallery-title-style {
    padding-bottom: 120px
}

.gallery-row-style {
    max-width: 1150px;
    margin-top: -30px;
}

.title-h2-gallery {
    font-size: 30px;
    color: #18255a;
}

.rounded-container {
    text-align: center;
}
.rounded-displayer-text {
    color: #979797;
    margin-bottom: 54px;
    margin-top: 16px;
    font-size: 20px;
}

.special-bold {
    margin-bottom: 25px;
    font-size: 30px;
    color: #18255a;
}

.special-normal {
    margin-top: 10px;
    font-size: 20px;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: #979797;
}

.description {
    max-width: 650px;
    margin-top: 25px;
    font-size: 20px;
    font-stretch: normal;
    line-height: normal;
    color: #949493;
}
