html > body {
    font-family: 'Lato', sans-serif;

}

div > p {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
}

div > h2 {
    font-family: 'Raleway', sans-serif;
}

div > h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 48px;
}

.external {
    max-width: 1024px;
    margin: auto;
}

a:hover, a:active, a:visited:hover, a:hover, a:visited:hover {
    color: #ee7810;
    text-decoration: none;
}

.triple-latam-container {
    padding-bottom: 120px;
    flex-wrap: wrap;
}

.triple-latam-styles {
    display: flex;
    justify-content: center;
    font-size: 48px;
    padding-bottom: 40px;
    color: #18255a;
    font-family: "Raleway", sans-serif;
}

.tree {
    background: rgba(68, 78, 121, 1);
    background: -moz-linear-gradient(left, rgba(68, 78, 121, 1) 0%, rgba(24, 37, 90, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(68, 78, 121, 1)), color-stop(100%, rgba(24, 37, 90, 1)));
    background: -webkit-linear-gradient(left, rgba(68, 78, 121, 1) 0%, rgba(24, 37, 90, 1) 100%);
    background: -o-linear-gradient(left, rgba(68, 78, 121, 1) 0%, rgba(24, 37, 90, 1) 100%);
    background: -ms-linear-gradient(left, rgba(68, 78, 121, 1) 0%, rgba(24, 37, 90, 1) 100%);
    background: linear-gradient(to right, rgba(68, 78, 121, 1) 0%, rgba(24, 37, 90, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#444e79', endColorstr='#18255a', GradientType=1);
    padding-top: 120px;
    padding-left: 10px;
}

a, a:visited {
    color: #ffffff;
    text-decoration: none;
}

.dropdown-toggle {
    color: #ffffff !important;
}

.bg-grey {
    background-color: #f5f5f5;
}

@keyframes QUOGO {
    25% {
        transform: translateY(0);
    }
    35% {
        right: 0;
        transform: translateY(25%);
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        right: 100%;
        transform: translateY(25%);
    }
}

.QUO {
    display: none;
    bottom: 0;
    position: fixed;
    transform: translateY(100%);
    right: 0;
}

.QUO-go {
    animation: QUOGO 2500ms;
}

.lazy-load-image-background.blur {
    filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    filter: blur(0);
    transition: filter .3s;
}

.lazy-load-image-background.blur > img {
    opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    opacity: 1;
    transition: opacity .3s;
}
