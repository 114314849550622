.title-style {
    position: relative;
    color: #ffffff;
    text-align: center;
    min-height: 644px;
    display: flex;
    align-items: center;
}

.title-contact-style {
    color: #ffffff;
    background: url("../../assets/bgDefault.svg") repeat-x center center;
}

.title-contact-title-style{
    font-size: 48px;
    color: #ffffff;
}

.title-h1-style {
    text-align: left;
}

.title-h1-contact-style {
    padding-top: 80px;
    text-align: left;
}

.first-description-style {
    padding-right: 50px;
    padding-top: 25px;
    text-align: left;
}

.label-style {
    width: 100%;
}

.form-text-style {
    text-align: left;
    font-size: 48px;
    color: #ee7810;
    font-family: "Raleway", sans-serif;
}

.btn-next {
    position: absolute;
    margin-top: 400px;
    width: 100%;
}

.form-container {
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    height: 680px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 25px 0 rgba(24, 37, 90, 0.1);
    z-index: 500;
}

.btn-send {
    width: 144px;
    height: 40px;
    border-radius: 20px;
    margin-top: 24px;
    background-color: #ee7810;
    color: #ffffff;
    border: none;
}

.contact-text {
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ee7810;
}

.who-style {
    text-align: left;
    font-size: 14px;
}

.who-style .form {
    font-weight: bold;
    color: #666666;
    font-size: 14px;
}

.who-style input[type="text"] {
    border: solid 1px #e4e4e4;
    margin-top: 24px;
    border-radius: 8px;
    height: 48px;
    max-width: 408px;
    width: 100%;
    padding-left: 30px;
}

.who-style input[type="email"] {
    border: solid 1px #e4e4e4;
    border-radius: 8px;
    margin-top: 24px;
    height: 48px;
    max-width: 408px;
    width: 100%;
    padding-left: 30px;
}

.who-style input[type="checkbox"] {
    margin-top: 24px;
    margin-right: 12px;
}

textarea {
    border: solid 1px #e4e4e4;
    border-radius: 8px;
    max-width: 408px;
    width: 100%;
    resize: none;
    margin-top: 24px;
    padding-left: 30px;
    padding-top: 25px;
}
