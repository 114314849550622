.table-subtitle {
    margin: auto;
    text-align: center;
    margin-bottom: 48px;
    font-size: 30px;
    color: #00a298;
}

.image-table-style {
    display: flex;
    flex-wrap: wrap;
    padding-top: 60px;
    padding-bottom: 120px;
}

.triple-image-table-style {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
}

.table-section-style {
    display: flex;
    flex-wrap: wrap;
}

.container-table {
    padding-bottom: 120px;
    padding-top: 120px;
}
